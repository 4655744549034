import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectCaseType,
  selectIndicatorsByCaseType,
} from '@/store/slices/caseTypes';
import CaseTypeIndicator from '../CaseTypeIndicator/CaseTypeIndicator';
import { CaseTypeId } from '@/store/types/basicTypes';

import {
  CaseCard,
  CaseCardHeader,
  CaseLabel,
  Divider,
  IndicatorsWrapper,
  IconButtonStyled as IconButton,
} from './styled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { selectIndicatorEntities } from '@/store/slices/indicators';
import convertEntitiesToArray from '@/store/utils/convertEntitiesToArray';
import useSearch from '@/hooks/useSearch';
import SearchInput from '../SearchInput';
import { Indicator } from '@/store/types/indicator';
import userActionInterceptor from '@/store/thunks/userActionInterceptor';

const CaseTypeCard = ({
  caseTypeId,
  isSelected,
}: {
  caseTypeId: CaseTypeId;
  isSelected: boolean;
}) => {
  const caseType = useAppSelector((state) => selectCaseType(state, caseTypeId));
  const dispatch = useAppDispatch();
  const indicatorIds = useAppSelector((state) =>
    selectIndicatorsByCaseType(state, caseTypeId)
  );

  const indicators = useAppSelector((state) => selectIndicatorEntities(state));

  const { searchResults, setSearchTerm, searchTerm } = useSearch<Indicator>({
    searchableEntities: convertEntitiesToArray(indicators).filter((i) =>
      indicatorIds.includes(i.id)
    ),
    searchKeys: ['label'],
  });

  const handleClick = () => {
    dispatch(
      isSelected
        ? userActionInterceptor({
            actions: [
              {
                action: 'removeSelectedCaseTypesThunk',
                payload: [caseTypeId],
              },
            ],
          })
        : userActionInterceptor({
            actions: [
              {
                action: 'addSelectedCaseTypesThunk',
                payload: { caseTypeIds: [caseTypeId] },
              },
            ],
          })
    );
  };

  return (
    <CaseCard
      isSelected={isSelected}
      aria-selected={isSelected}
      data-testid={`case-type-card-${caseTypeId}`}
    >
      <CaseCardHeader data-testid={`case-type-card-header-${caseTypeId}`}>
        <CaseLabel sx={{ flex: 3 }} data-testid={'case-label'}>
          {caseType.label}
        </CaseLabel>
        <SearchInput
          sx={{ mt: 1, flex: 2 }}
          value={searchTerm}
          onChange={(val) => setSearchTerm(val as string)}
        />
        <IconButton onClick={handleClick}>
          {isSelected ? (
            <CheckCircleIcon
              data-testid='case-type-selected-confirmation-icon'
              aria-selected={isSelected}
              color='primary'
            />
          ) : (
            <CheckCircleOutlineIcon
              data-testid='case-type-selected-confirmation-icon'
              aria-selected={isSelected}
              color='primary'
            />
          )}
        </IconButton>
      </CaseCardHeader>
      <Divider />
      <IndicatorsWrapper>
        {searchResults
          .flatMap((r) => r.id)
          .map((id) => (
            <CaseTypeIndicator key={id} indicatorId={id} />
          ))}
      </IndicatorsWrapper>
    </CaseCard>
  );
};

export default CaseTypeCard;
