import React, { useEffect, useState } from 'react';
import { Link, TextField } from '@s-rm/react-ui-lib';
import fuzzy from 'fuzzy';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { IndicatorId } from '@/store/types/basicTypes';
import Popover from '../Popover/Popover';
import {
  selectAvailableIndicators,
  selectDismissedIndicators,
  selectIndicatorEntities,
  selectSelectedIndicators,
  selectIndicatorEntitiesAsArray,
} from '@/store/slices/indicators';
import { Indicator, IndicatorActions } from '@/store/types/indicator';
import changeIndicatorStateThunk from '@/store/thunks/changeIndicatorStateThunk';
import {
  Chip,
  ChipContainer,
  LinkSeperator,
  ScrollContainer,
  SelectionHeader,
  SelectionItem,
  SelectionTitle,
  SeperatorText,
  SingleLinkSeperator,
} from './styled';
import getFilteredIndicators from '../utils/getFilteredIndicators';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import userActionInterceptor from '@/store/thunks/userActionInterceptor';

export const IndicatorSelect = () => {
  const dispatch = useAppDispatch();
  const indicatorEntities = useAppSelector(selectIndicatorEntities);
  const selectedIndicators = useAppSelector(selectSelectedIndicators);
  const dismissedIndicators = useAppSelector(selectDismissedIndicators);
  const availableIndicators = useAppSelector(selectAvailableIndicators);
  const searchEntitiesArray = useAppSelector(selectIndicatorEntitiesAsArray);

  const [searchResults, setSearchResults] = useState<Indicator[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const shouldLoadSearchResults =
      !searchResults.length && !!searchEntitiesArray.length;

    if (shouldLoadSearchResults) {
      setSearchResults(searchEntitiesArray);
    }
  }, [searchEntitiesArray, searchResults.length]);

  const handleInputChange = (val: string) => {
    const results = fuzzy.filter(val, searchEntitiesArray, {
      extract: (item) => item.label,
    });
    const matches = results.map((item) => item.original);
    setSearchTerm(val);
    setSearchResults(matches);
  };

  const handleIndicatorStateChange = (
    indicatorId: IndicatorId,
    action: IndicatorActions
  ) => {
    dispatch(
      userActionInterceptor({
        actions: [
          {
            action: 'changeIndicatorStateThunk',
            payload: { indicatorId, action },
          },
        ],
      })
    );
  };

  return (
    <Popover buttonText='Add Indicators' width={600}>
      <SelectionHeader>
        <SelectionTitle>Add indicators</SelectionTitle>
        <TextField
          data-testid='search-indicators'
          size='small'
          placeholder='Search for indicators'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchTerm}
          onChange={(e) => handleInputChange(e.target.value)}
        />
      </SelectionHeader>
      <ScrollContainer>
        <ChipContainer data-testid='selected-indicators'>
          {selectedIndicators.map((indicatorId) => {
            const indicator = indicatorEntities[indicatorId];
            return (
              <Chip
                data-testid={`selected-indicator-${indicatorId}`}
                key={indicatorId}
                label={indicator.label}
                onDelete={() =>
                  handleIndicatorStateChange(
                    indicatorId,
                    IndicatorActions.Deselect
                  )
                }
              />
            );
          })}
        </ChipContainer>
        <div data-testid='available-indicators'>
          <SeperatorText>All</SeperatorText>
          {getFilteredIndicators(availableIndicators, searchResults).map(
            (indicatorId) => {
              const indicator = indicatorEntities[indicatorId];
              return (
                <SelectionItem key={indicatorId}>
                  <div>{indicator.label}</div>
                  <LinkSeperator>
                    <Link
                      data-testid={`dismiss-indicator-${indicatorId}`}
                      sx={{ color: 'common.black' }}
                      onClick={() =>
                        handleIndicatorStateChange(
                          indicatorId,
                          IndicatorActions.Dismiss
                        )
                      }
                    >
                      Dismiss
                    </Link>
                  </LinkSeperator>
                  <Link
                    data-testid={`add-indicator-${indicatorId}`}
                    onClick={() =>
                      handleIndicatorStateChange(
                        indicatorId,
                        IndicatorActions.Select
                      )
                    }
                  >
                    Add
                  </Link>
                </SelectionItem>
              );
            }
          )}
        </div>
        <div data-testid='dismissed-indicators'>
          <SeperatorText>Dismissed</SeperatorText>
          {getFilteredIndicators(dismissedIndicators, searchResults).map(
            (indicatorId) => {
              const indicator = indicatorEntities[indicatorId];
              return (
                <SelectionItem key={indicatorId}>
                  <div>{indicator.label}</div>
                  <SingleLinkSeperator>
                    <Link
                      data-testid={`undo-dismiss-indicator-${indicatorId}`}
                      onClick={() =>
                        handleIndicatorStateChange(
                          indicatorId,
                          IndicatorActions.Revive
                        )
                      }
                    >
                      Undo
                    </Link>
                  </SingleLinkSeperator>
                </SelectionItem>
              );
            }
          )}
        </div>
      </ScrollContainer>
    </Popover>
  );
};

export default IndicatorSelect;
