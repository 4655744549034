import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectAllCaseTypesAsArray,
  selectSelectedCaseTypeIds,
} from '@/store/slices/caseTypes';
import { CaseTypeId } from '@/store/types/basicTypes';
import Popover from '../Popover/Popover';
import { Checkbox, TextField } from '@s-rm/react-ui-lib';
import { InputAdornment, List, ListItem, ListItemText } from '@mui/material';
import fuzzy from 'fuzzy';
import {
  ListItemButton,
  ScrollContainer,
  SelectionHeader,
  SelectionTitle,
} from './styled';
import SearchIcon from '@mui/icons-material/Search';
import useCaseClosure from '@/hooks/useCaseClosure';
import userActionInterceptor from '@/store/thunks/userActionInterceptor';

export const CaseTypeSelect = () => {
  const dispatch = useAppDispatch();
  const { caseClosureId } = useCaseClosure();
  const caseTypes = useAppSelector(selectAllCaseTypesAsArray)
    .map((caseType) => ({
      id: caseType.id,
      label: caseType.label,
    }))
    .filter((caseType) => caseType.id !== caseClosureId);

  const initialSelectedCaseTypes = useAppSelector(selectSelectedCaseTypeIds);
  const [searchResults, setSearchResults] = useState(caseTypes);
  const [searchTerm, setSearchTerm] = useState('');
  const [caseTypeSelection, setCaseTypeSelection] = useState<{
    selected: CaseTypeId[];
    unselected: CaseTypeId[];
  }>({
    selected: initialSelectedCaseTypes,
    unselected: [],
  });

  useEffect(() => {
    const shouldLoadCaseTypes = !searchResults.length && caseTypes.length;
    if (shouldLoadCaseTypes) {
      setSearchResults(caseTypes);
    }
  }, [searchResults, caseTypes]);

  useEffect(() => {
    setCaseTypeSelection({
      selected: initialSelectedCaseTypes,
      unselected: [],
    });
  }, [initialSelectedCaseTypes]);

  const handleChange = (caseTypeId: CaseTypeId) => {
    const isSelected = caseTypeSelection.selected.includes(caseTypeId);

    setCaseTypeSelection({
      selected: isSelected
        ? caseTypeSelection.selected.filter((id) => id !== caseTypeId)
        : [...caseTypeSelection.selected, caseTypeId],
      unselected: isSelected
        ? [...caseTypeSelection.unselected, caseTypeId]
        : caseTypeSelection.unselected.filter((id) => id !== caseTypeId),
    });
  };

  const handleInputChange = (val: string) => {
    const results = fuzzy.filter(val, caseTypes, {
      extract: (item) => item.label,
    });
    const matches = results.map((item) => item.original);

    setSearchTerm(val);
    setSearchResults(matches);
  };

  const handleClose = async () => {
    dispatch(
      userActionInterceptor({
        actions: [
          {
            action: 'addSelectedCaseTypesThunk',
            payload: { caseTypeIds: caseTypeSelection.selected },
          },
          {
            action: 'removeSelectedCaseTypesThunk',
            payload: caseTypeSelection.unselected,
          },
        ],
      })
    );
  };

  return (
    <Popover buttonText='Add case type' width={600} onClose={handleClose}>
      <SelectionHeader>
        <SelectionTitle>Add case type</SelectionTitle>
        <TextField
          placeholder='Search for case type'
          value={searchTerm}
          onChange={(e) => handleInputChange(e.target.value)}
          size='small'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </SelectionHeader>
      <ScrollContainer>
        <List>
          {searchResults.map((caseType) => {
            const isSelected = !!caseTypeSelection.selected.includes(
              caseType.id
            );

            return (
              <ListItem
                data-testid={`select-case-type-${caseType.id}`}
                aria-selected={isSelected}
                key={caseType.id}
                secondaryAction={
                  <Checkbox
                    checked={isSelected}
                    onClick={() => handleChange(caseType.id)}
                  />
                }
                disablePadding
              >
                <ListItemButton onClick={() => handleChange(caseType.id)}>
                  <ListItemText primary={caseType.label} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </ScrollContainer>
    </Popover>
  );
};

export default CaseTypeSelect;
