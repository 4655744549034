import { styled } from '@mui/material';
import { ChipLegacy as MUIChip } from '@s-rm/react-ui-lib';

export const Chip = styled(MUIChip)`
  margin-right: ${({ theme }) => theme.spacing(2)} !important;
  margin-bottom: ${({ theme }) => theme.spacing(2)} !important;
  border-radius: 0px;
  .MuiChip-label {
    overflow: visible;
  }
`;

export const LabelWrapper = styled('div')`
  position: relative;
`;

export const AiIndicator = styled('div')`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: absolute;
  background-color: #fff;
  width: 17px;
  height: 17px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 50%;
  right: 0;
  top: 0;
  margin-top: -12px;
  margin-right: -17px;
  font-weight: bold;
  font-size: 10px;
  color: ${({ theme }) => theme.palette.primary.main};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
`;
