import { Divider as MUIDivider, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const CaseCard = styled('div')<{ isSelected: boolean }>`
  background-color: ${({ theme }) => theme.palette.common.white};
  margin-top: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-color: ${({ theme, isSelected }) =>
    isSelected && theme.palette.primary.main};
  box-shadow: ${({ theme, isSelected }) => isSelected && theme.shadows[3]};
  transition: all ${({ theme }) => theme.transitions.duration.short}ms;
`;

export const CaseCardHeader = styled('div')`
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: center;
  h5 {
    margin: 0;
  }
`;

export const CaseLabel = styled('div')`
  font-weight: 600;
`;

export const Divider = styled(MUIDivider)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const IndicatorsWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const IconButtonStyled = styled(IconButton)`
  margin-left: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;
