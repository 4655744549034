import * as React from 'react';
import { useAppSelector } from '@/hooks';
import {
  selectSelectedCaseTypeIds,
  selectSuggestedCaseTypes,
} from '@/store/slices/caseTypes';

import CaseTypeCard from '../CaseTypeCard/CaseTypeCard';
import { CaseTypeSelect } from '../CaseTypeSelect/CaseTypeSelect';
import IndicatorSelect from '../IndiatorSelect/IndicatorSelect';
import {
  ButtonSeperator,
  CaseTypeButtons,
  CaseTypeCount,
  CaseTypeHeader,
} from './styled';
import EmptyItemsPlaceholder from '../EmptyItemsPlaceholder/EmptyItemsPlaceholder';

const CaseTypeList = () => {
  const selectedCaseTypes = useAppSelector(selectSelectedCaseTypeIds);
  const suggestedCaseTypes = useAppSelector(selectSuggestedCaseTypes);

  return (
    <>
      <CaseTypeHeader>
        <h4>Case type</h4>
        <CaseTypeCount data-testid='case-type-count'>
          {selectedCaseTypes.length}
        </CaseTypeCount>
        <CaseTypeButtons>
          <CaseTypeSelect data-testid='add-case-type' />
          <ButtonSeperator>
            <IndicatorSelect data-testid='add-indicator' />
          </ButtonSeperator>
        </CaseTypeButtons>
      </CaseTypeHeader>
      {suggestedCaseTypes.length ? (
        suggestedCaseTypes.map((id) => (
          <CaseTypeCard
            isSelected={selectedCaseTypes.includes(id)}
            key={id}
            caseTypeId={id}
          />
        ))
      ) : (
        <EmptyItemsPlaceholder
          text={
            <div>
              Add <span>Case type</span> or <span>Indicators</span> and
              suggestions will appear.
            </div>
          }
        />
      )}
    </>
  );
};

export default CaseTypeList;
