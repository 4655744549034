import { Indicator } from '@/store/types/indicator';

const getFilteredIndicators = (
  indicators: string[],
  searchResults: Indicator[]
) =>
  indicators.filter(
    (indicator) =>
      !!searchResults.find((searchResult) => searchResult.id === indicator)
  );

export default getFilteredIndicators;
