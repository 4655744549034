import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const useRouteChange = (handler: () => void, dependencies: any) => {
  const { events, route } = useRouter();
  useEffect(() => {
    events.on('routeChangeStart', handler);
    return () => {
      events.off('routeChangeStart', handler);
    };
  }, [events, route, handler, dependencies]);
};
