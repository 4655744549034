import { styled } from '@mui/material';

export const CaseTypeHeader = styled('div')`
  display: flex;
  text-transform: uppercase;
`;

export const CaseTypeButtons = styled('div')`
  display: flex;
  margin-left: auto;
`;

export const ButtonSeperator = styled('div')`
  margin-left: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const CaseTypeCount = styled('div')`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  display: flex;
  width: 1.3em;
  height: 1.3em;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

export const PlaceholderIconWrapper = styled('div')`
  background: ${({ theme }) => theme.palette.grey[200]};
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  & > svg {
    font-size: 2.2rem;
    fill: ${({ theme }) => theme.palette.grey[400]};
  }
`;

export const PlaceholderText = styled('div')`
  & > span {
    font-weight: 600;
  }
`;

export const PlaceholderWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: calc(100% - 50px);
  justify-content: center;
`;
