import { styled } from '@mui/material';
import { ChipLegacy as MUIChip } from '@s-rm/react-ui-lib';

export const SelectionHeader = styled('div')`
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const SelectionTitle = styled('div')`
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const ScrollContainer = styled('div')`
  height: 400px;
  overflow-y: auto;
  padding: 0 ${({ theme }) => theme.spacing(3)} 0
    ${({ theme }) => theme.spacing(3)};
`;

export const SeperatorText = styled('div')`
  color: ${({ theme }) => theme.palette.grey[500]};
  margin: ${({ theme }) => theme.spacing(2)} 0
    ${({ theme }) => theme.spacing(2)} 0;
`;

export const LinkSeperator = styled('div')`
  margin-left: auto;
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const SingleLinkSeperator = styled('div')`
  margin-left: auto;
`;

export const SelectionItem = styled('div')`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;

export const ChipContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

export const Chip = styled(MUIChip)`
  margin-right: ${({ theme }) => theme.spacing(1.5)} !important;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)} !important;
`;
